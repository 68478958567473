/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

// CONTEXT
import CustomDate from 'components/CustomDate'

// MATERIAL
import { FormControl } from '@material-ui/core'
import { Grid, IconButton } from '@mui/material'
import { Add, Remove } from '@material-ui/icons'
import { CssTextField } from 'components/CssTextField/CssTextField'

// Styles
import {
    Button,
    MuiAutocomplete,
    MuiBox,
    MuiStack,
} from '../../../components/storybook'
import { MoreFilter } from './styles'

import { status } from '../utils'

const SearchForm = ({ ...sharedProps }) => {
    const { getNegotiationFilter, t, theme, currentTab } = sharedProps

    const backgroundColor = theme?.navigation?.buttons?.backgroundColor
    const [showSecondContainer, setShowSecondContainer] = useState(false)

    const {
        control,
        handleSubmit,
        watch,
        reset,
        formState: { isSubmitting },
    } = useForm({})

    const toggleSecondContainer = () => {
        setShowSecondContainer(!showSecondContainer)
    }

    const watchAllFields = watch()

    const handleFormSubmit = async (data) => {

        const filter = {
            negotiationCampaign: data?.name_negotiation,
            Status: data?.status?.value,
            startPeriod:
                data.dateFilterTo && data.dateFilterTo
                    ? new Date(data.dateFilterTo).toISOString()
                    : null,
            endPeriod:
                data.dateFilterExpiration && data.dateFilterExpiration
                    ? new Date(data.dateFilterExpiration).toISOString()
                    : null,
            createdAt:
                data.dateCreated && data.dateCreated
                    ? new Date(data.dateCreated).toISOString()
                    : null,
            TypeOfBenefit: currentTab === 1 ? 'Cashback' : 'Points',
        }

        await getNegotiationFilter(filter)
    }

    const [resetKey, setResetKey] = useState(0)

    const handleClear = () => {
        reset({
            name_negotiation: '',
            dateFilterExpiration: null,
            dateFilterTo: null,
            dateCreated: null,
            status: null,
        })

        setResetKey((prevKey) => prevKey + 1)
        getNegotiationFilter({})
    }

    return (
        <MuiBox
            sx={{
                backgroundColor: '#F8F8F8',
                padding: '21px 20px 20px 20px',
                borderRadius: '5px',
                width: '100%',
                '.MuiInputBase-input.Mui-disabled': {
                    opacity: 1,
                    background: '#F2F2F2 !important',
                },
                '.MuiStack-root': {
                    height: 'auto',
                },
                '.MuiAutocomplete-root': {
                    height: 'auto',
                },
            }}
            mt={3}
        >
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <Grid container spacing={1.4}>
                    <Grid item xs={12} md={6} lg={5.1}>
                        <Controller
                            name="name_negotiation"
                            control={control}
                            render={({ ...field }) => (
                                <CssTextField
                                    {...field}
                                    style={{
                                        width: '100%',
                                        backgroundColor: '#fff',
                                    }}
                                    label={t('Origem da negociação')}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    margin="dense"
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={2.3}>
                        <Controller
                            key={resetKey}
                            control={control}
                            name="dateCreated"
                            render={({ ...field }) => (
                                <CustomDate
                                    {...field}
                                    label={'Vigência'}
                                    enableDates={true}
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={2.3}>
                        <Controller
                            key={resetKey}
                            control={control}
                            name="dateFilterTo"
                            render={({ ...field }) => (
                                <CustomDate
                                    {...field}
                                    label="Inicio"
                                    enableDates={true}
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={2.3}>
                        <Controller
                            key={resetKey}
                            control={control}
                            name="dateFilterExpiration"
                            render={({ ...field }) => (
                                <CustomDate
                                    {...field}
                                    label="Expiração"
                                    enableDates={true}
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    {showSecondContainer && (
                        <Grid
                            item
                            xs={12}
                            md={6}
                            lg={3}
                            sx={{
                                '& .has-label': {
                                    transform: 'translateY(-17px) !important',
                                },
                            }}
                        >
                            <FormControl
                                fullWidth
                                size="small"
                                variant="outlined"
                            >
                                <Controller
                                    name="status"
                                    defaultValue=""
                                    control={control}
                                    render={({ onChange, value }) => (
                                        <MuiAutocomplete
                                            options={status}
                                            required
                                            textLabel={
                                                watchAllFields.status
                                                    ? 'Status'
                                                    : ' '
                                            }
                                            showLabel
                                            value={value}
                                            onChange={(_e, newValue) => {
                                                onChange(newValue)
                                            }}
                                            placeholder="Status"
                                            defaultColor={
                                                theme?.navigation?.buttons
                                                    ?.backgroundColor
                                            }
                                            sx={{
                                                background: '#FFFFFF',
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    )}
                </Grid>
                <MuiStack
                    direction="row"
                    spacing={2}
                    display="flex"
                    justifyContent="space-between"
                    mt={3.2}
                    sx={{
                        flexDirection: {
                            xs: 'column',
                            sm: 'row',
                        },
                        gap: {
                            xs: 3,
                            sm: 0,
                        },
                    }}
                >
                    <MuiStack
                        direction="row"
                        display="flex"
                        alignItems="center"
                        spacing={1}
                    >
                        <MoreFilter title="Pesquisa avançada" theme={theme}>
                            <IconButton
                                classes={{ root: 'custom-icon-button' }}
                                title="Pesquisa avançada"
                                onClick={toggleSecondContainer}
                            >
                                {!showSecondContainer ? <Add /> : <Remove />}
                            </IconButton>
                            <span onClick={toggleSecondContainer}>
                                {!showSecondContainer
                                    ? t(' Ocultar filtros')
                                    : t('Mais filtros')}
                            </span>
                        </MoreFilter>
                    </MuiStack>
                    <MuiStack
                        direction="row"
                        display="flex"
                        gap={2}
                        alignItems="center"
                    >
                        <Button
                            text={t('BUTTON_FIND')}
                            typeVariation="TextButton"
                            type="submit"
                            textColor="#FFFFFF"
                            disabled={isSubmitting}
                            sx={{
                                color: '#FFFFFF!important',
                                textTransform: 'unset',
                                width: '100%',
                                height: '40px',
                                padding: '5px 15px',
                                backgroundColor: isSubmitting
                                    ? '#D9D9D9'
                                    : backgroundColor + '!important',
                            }}
                        />
                        <Button
                            text="Limpar"
                            typeVariation="CleanButton"
                            onClick={handleClear}
                            sx={{
                                color: backgroundColor + '!important',
                                borderColor: backgroundColor + '!important',
                                width: '100%',
                                height: '40px',
                                textTransform: 'unset',
                                '&:hover': {
                                    borderColor: backgroundColor + '!important',
                                    backgroundColor:
                                        backgroundColor + '!important',
                                },
                            }}
                        />
                    </MuiStack>
                </MuiStack>
            </form>
        </MuiBox>
    )
}

export default SearchForm
