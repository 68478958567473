import React from 'react'
import * as _ from 'lodash'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Switch, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { Grid } from '@material-ui/core'

// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.jsx'
import Banner from 'components/Banner'
import Footer from 'components/Footer'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Loader from 'components/Loader/Loader.jsx'
import NotificationModal from 'components/NotificationModal/index.js'
import RenderDescription from 'components/NotificationModal/RenderDescription'
import Sidebar from 'components/Sidebar/Sidebar.jsx'
import {
    BodyRenderModal,
    NotificationSkeleton,
} from 'components/NotificationModal/styles.js'

import appStyle from 'assets/jss/material-dashboard-pro-react/layouts/adminStyle.jsx'
import checkStyle from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx'
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'

import logoF from 'assets/img/logo_Germini.png'
import logoP from 'assets/img/logo_Germini_Marca.png'

import {
    GetCurrentConsumer,
    GetData,
    GetPlatformRulesData,
    GetRegulationData,
    HideLoader,
    PostConsumerRegulation,
    ShowLoader,
} from 'redux/actions'

import { GetPlatformRules } from 'redux/api/platformRules.js'

import { GetRegulationData as GetRegulationRules } from 'redux/api/regulation.js'

import {
    GetConnectionId,
    GetConsumerImportantAlertsId,
    ReadAlert,
} from 'redux/api/alerts/alerts.js'

import { dashRoutes } from './routes.js'

// Utils
import compose from 'utils/compose'
import { RequireAuth } from '../../utils/RequireAuth.js'
import { GetThemeColorFromStorage } from '../../redux/api/themeColor.js'
import PlatformRulesModal from './PlatformRulesModal/index.jsx'
import { GetNegotiation } from '../../redux/actions/negotiation.js'
import { Modal } from './Components/Modal/index.jsx'
import { GetAvailableSearch } from './function/index.js'

class Home extends React.Component {
    constructor(props) {
        super(props)
        this.modalRG = React.createRef()
        this.state = {
            acceptance: false,
            accepted: false,
            alertRG: false,
            alerts: '',
            bgColor: 'white',
            color: 'green',
            contentEditor: '',
            days: 0,
            firstEntry: 0,
            fixedClasses: 'dropdown',
            hasImage: false,
            hasNotification: false,
            isNotification: false,
            image: null,
            logo: logoF,
            miniActive: false,
            mobileOpen: false,
            presentContent: 'Regulamento',
            regulationData: [],
            showImportantNotification: false,
            useTermsData: {},
            themeColor: {},
            notifications: {
                total: 0,
                presentPage: 1,
                idList: [],
                data: {},
                loading: false,
            },
            readAllImportantAlerts: false,
            showNegotiation: false,
            searchResults: [],
            modalShown: false,
        }
        this.resizeFunction = this.resizeFunction.bind(this)
    }

    async componentDidMount() {
        this.props.ShowLoader(<Loader />)
        this.props.GetData()
        GetThemeColorFromStorage()

        const modalShown = localStorage.getItem('modalShown')
        if (!modalShown) {
            this.setState({ modalShown: true })
            localStorage.setItem('modalShown', 'true')
        }

        if (_.isEmpty(this.props.userData)) {
            this.props.GetCurrentConsumer()
        }

        this.setState({
            themeColor: GetThemeColorFromStorage(),
        })

        this.getTermsAndRegulation()

        // VERIFY ROUTE PAGE TO HOME
        if (this.props.location.pathname === '/') {
            this.props.history.push(`/home`)
        }

        GetNegotiation()
        await this.getResults()

        const show = localStorage.getItem('showNegotiations')

        this.setState({ showNegotiation: Boolean(show) ?? false })

        const getImportantAlerts = async () => {
            try {
                this.setState({
                    notifications: {
                        ...this.state.notifications,
                        loading: true,
                    },
                })

                const response = await GetConsumerImportantAlertsId()
                if (response?.length > 0) {
                    const responseData = await ReadAlert(response[0])
                    this.setState({
                        hasNotification: true,
                        notifications: {
                            total: response?.length,
                            presentPage: 1,
                            idList: response,
                            data: responseData,
                        },
                    })
                }
                const connectId = await GetConnectionId()

                if (connectId) {
                    this.setState({
                        alerts: connectId.filter(
                            (item) => item.type.value === 'ALERT',
                        )[0],
                    })
                }
            } catch (error) {
                console.log('Dashboard -> getImportantAlerts -> error', error)
            } finally {
                this.setState({
                    notifications: {
                        ...this.state.notifications,
                        loading: false,
                    },
                })
            }
        }
        await getImportantAlerts()
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.state.firstEntry > 1 &&
            prevState.alertRG !== this.state.alertRG &&
            this.state.useTermsData &&
            this.state.useTermsData.consumerAgreements &&
            !this.state.useTermsData.consumerAgreements
                ?.acceptedCurrentUseTerms &&
            this.state.presentContent === 'Regulamento'
        ) {
            this.setState({
                alertRG: true,
                presentContent: 'Termos de uso e Política de privacidade',
                contentEditor: this.state.useTermsData.useTerms,
                days: this.state.useTermsData.consumerAgreements
                    .daysRemainingUseTerms,
            })
        }

        if (prevProps.userData !== this.props.userData) {
            this.setState({ userData: this.props.userData })
        }
        if (prevProps.generalRulesData !== this.props.generalRulesData) {
            this.setState({ generalRulesData: this.props.generalRulesData })
            localStorage.setItem(
                'programName',
                this.props.generalRulesData.name,
            )
            localStorage.setItem(
                'currencySymbol',
                this.props.generalRulesData.currencySymbol,
            )
            localStorage.setItem(
                'programCurrencySymbol',
                this.props.generalRulesData.programCurrencySymbol,
            )
            localStorage.setItem(
                'programCurrencyName',
                this.props.generalRulesData.programCurrencyName,
            )
            localStorage.setItem(
                'allowPointsConversion',
                this.props.generalRulesData.allowPointsConversion,
            )
        }

        if (prevProps.userDataFail !== this.props.userDataFail) {
            this.props.history.push('/auth/login')
            this.props.HideLoader()
        }
        if (
            prevProps.generalRulesdataFailed !==
            this.props.generalRulesdataFailed
        ) {
            this.props.HideLoader()
        }

        const show = localStorage.getItem('showNegotiations')
        dashRoutes[3].sidebar = Boolean(show) ?? false
        // console.log('dashRoutes[3].sidebar', dashRoutes[3].sidebar)
    }

    getTermsAndRegulation = async () => {
        this.props.ShowLoader(<Loader />)
        const responseRegulation = await GetRegulationRules()
        const responseUseTerms = await GetPlatformRules()

        this.setState({
            firstEntry: this.state.firstEntry + 1,
            presentContent:
                responseRegulation?.consumerAgreements &&
                responseRegulation?.consumerAgreements.acceptedCurrentRegulation
                    ? 'Termos de uso e Política de privacidade'
                    : 'Regulamento',
            regulationData: responseRegulation,
            useTermsData: responseUseTerms,

            contentEditor: responseRegulation?.consumerAgreements
                ?.acceptedCurrentRegulation
                ? responseUseTerms?.useTerms
                : responseRegulation?.regulation,
            days: responseRegulation?.consumerAgreements
                ?.acceptedCurrentRegulation
                ? responseUseTerms?.consumerAgreements.daysRemainingUseTerms
                : responseRegulation?.consumerAgreements
                      ?.daysRemainingRegulation,
            acceptance: responseRegulation?.consumerAgreements
                ?.acceptedCurrentRegulation
                ? responseUseTerms?.consumerAgreements?.acceptedCurrentUseTerms
                : responseRegulation?.consumerAgreements
                      ?.acceptedCurrentRegulation,
            alertRG: responseRegulation?.consumerAgreements
                ?.acceptedCurrentRegulation
                ? !responseUseTerms?.consumerAgreements?.acceptedCurrentUseTerms
                : !responseRegulation?.consumerAgreements
                      ?.acceptedCurrentRegulation,

            showImportantNotification:
                responseRegulation?.consumerAgreements
                    ?.acceptedCurrentRegulation &
                responseRegulation?.consumerAgreements?.acceptedCurrentUseTerms,
        })

        this.props.HideLoader()
    }

    async changePage(page, readAllImportantAlerts) {
        try {
            this.setState({
                notifications: {
                    ...this.state.notifications,
                    loading: true,
                },
            })
            const responseData = await ReadAlert(
                this.state.notifications.idList[page - 1],
                readAllImportantAlerts ? readAllImportantAlerts : '',
            )
            this.setState({
                notifications: {
                    ...this.state.notifications,
                    presentPage: page,
                    data: responseData,
                },
            })
        } catch (error) {
            console.log('Dashboard -> changePage -> error', error)
        } finally {
            this.setState({
                notifications: {
                    ...this.state.notifications,
                    loading: false,
                },
            })
        }
    }

    getResults = async () => {
        try {
            const response = await GetAvailableSearch()
            this.setState({ isNotification: true, searchResults: response })
        } catch (error) {
            console.error('Error in getResults:', error)
        }
    }

    handleImageClick = (image) => {
        this.setState({ image: image })
    }

    handleColorClick = (color) => {
        this.setState({ color: color })
    }

    handleBgColorClick = (bgColor) => {
        this.setState({ bgColor: bgColor })
    }

    handleFixedClick = () => {
        if (this.state.fixedClasses === 'dropdown') {
            this.setState({ fixedClasses: 'dropdown show' })
        } else {
            this.setState({ fixedClasses: 'dropdown' })
        }
    }

    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen })
    }

    getRoute() {
        return this.props.location.pathname !== '/full-screen-maps'
    }

    handleClose() {
        if (this.state.days === 0) this.props.history.push('/auth/login')
        this.setState({ alertRG: false, firstEntry: this.state.firstEntry + 1 })
    }

    handleChangeSwitch = () => {
        this.setState({ accepted: !this.state.accepted })
    }

    handleCloseModal = () => {
        this.setState({ modalShown: false })
    }

    getActiveRoute = (dashRoutes) => {
        const { t } = this.props

        let activeRoute = ''
        for (let i = 0; i < dashRoutes.length; i++) {
            if (dashRoutes[i].collapse) {
                let collapseActiveRoute = this.getActiveRoute(
                    dashRoutes[i].views,
                )
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute
                }
            } else {
                var path = dashRoutes[i].path.replace(':id', '')
                if (
                    window.location.href.indexOf(
                        dashRoutes[i].layout + path,
                    ) !== -1
                ) {
                    return t(dashRoutes[i].name)
                }
            }
        }
        return activeRoute
    }

    getRoutes = (dashRoutes) => {
        return dashRoutes.map((prop, key) => {
            if (prop.collapse) {
                return this.getRoutes(prop.views)
            }
            if (prop.layout === '/admin') {
                const MyComponent = prop.component

                if (prop.requiresDigitalWallet) {
                    return (
                        <Route
                            path={prop.path}
                            key={key}
                            render={(props) => (
                                <RequireAuth>
                                    <MyComponent {...props} />
                                </RequireAuth>
                            )}
                        />
                    )
                }
                return (
                    <Route
                        path={prop.path}
                        component={prop.component}
                        key={key}
                    />
                )
            } else {
                return null
            }
        })
    }

    sidebarMinimize() {
        this.setState({ miniActive: !this.state.miniActive })
        if (!this.state.miniActive) {
            this.setState({ logo: logoP })
        } else {
            this.setState({ logo: logoF })
        }
    }

    resizeFunction() {
        if (window.innerWidth >= 960) {
            this.setState({ mobileOpen: false })
        }
    }
    render() {
        const {
            isNotification,
            hasNotification,
            showImportantNotification,
            notifications,
        } = this.state
        const {
            t,
            classes,
            user,
            users,
            alert,
            loading,
            userData,
            generalRulesData,
            ...rest
        } = this.props
        const mainPanel =
            classes.mainPanel +
            ' ' +
            cx({
                [classes.mainPanelSidebarMini]: this.state.miniActive,
                [classes.mainPanelWithPerfectScrollbar]:
                    navigator.platform.indexOf('Win') > -1,
            })

        return (
            <>
                <AdminNavbar
                    sidebarMinimize={this.sidebarMinimize.bind(this)}
                    miniActive={this.state.miniActive}
                    brandText={this.getActiveRoute(dashRoutes)}
                    handleDrawerToggle={this.handleDrawerToggle}
                    {...rest}
                />
                <GridContainer>
                    {/* {!_.isEmpty(this.props.userData) && (
                        <Banner userData={this.props.userData} />
                    )} */}

                    <Banner
                        key={this.props.userData}
                        userData={this.props.userData}
                    />
                </GridContainer>
                {alert}
                {loading}
                {this.state.days !== 0 && (
                    <>
                        <div
                            className={classes.wrapper}
                            // key={this?.state?.showNegotiation || dashRoutes}
                            key={dashRoutes}
                        >
                            {this.props.userData && (
                                <Sidebar
                                    key={this.props.userData}
                                    routes={dashRoutes}
                                    logo={this.state.logo}
                                    image={this.state.image}
                                    handleDrawerToggle={this.handleDrawerToggle}
                                    open={this.state.mobileOpen}
                                    color={this.state.color}
                                    bgColor={this.state.bgColor}
                                    miniActive={this.state.miniActive}
                                    user={user}
                                    userData={this.state.userData}
                                    generalRulesData={
                                        this.state.generalRulesData
                                    }
                                    regulationWarning={
                                        !this.props?.userData?.agreements
                                            ?.acceptedCurrentRegulation
                                    }
                                    useTermsWarning={
                                        !this.props?.userData?.agreements
                                            ?.acceptedCurrentUseTerms
                                    }
                                    {...rest}
                                />
                            )}
                            <div className={mainPanel} ref="mainPanel">
                                {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
                                {this.getRoute() ? (
                                    <div className={classes.content}>
                                        <div className={classes.container}>
                                            <Switch>
                                                {this.getRoutes(dashRoutes)}
                                            </Switch>
                                        </div>
                                    </div>
                                ) : (
                                    <div className={classes.map}>
                                        <Switch>
                                            {this.getRoutes(dashRoutes)}
                                        </Switch>
                                    </div>
                                )}
                                {/* {this.getRoute() ? <Footer fluid /> : null} */}
                            </div>
                        </div>
                        <GridContainer>
                            <Footer />
                        </GridContainer>
                    </>
                )}
                <NotificationModal
                    headerTitle="MENU_NOTIFICATION"
                    width="900px"
                    height="500px"
                    headerIcon={false}
                    closeByClick={() =>
                        this.setState({ hasNotification: false })
                    }
                    open={showImportantNotification && hasNotification}
                    showCheckBox={true}
                    buttonTitle="BUTTON_CLOSE"
                    totalPage={notifications.total}
                    sendPage={(page, readAllImportantAlerts) =>
                        this.changePage(page, readAllImportantAlerts)
                    }
                    presentPage={notifications.presentPage}
                >
                    {notifications.loading ? (
                        <div>
                            <NotificationSkeleton
                                variant="rect"
                                width="840px"
                                height="40px"
                            ></NotificationSkeleton>
                            <NotificationSkeleton
                                variant="rect"
                                width="840px"
                                height="40px"
                            ></NotificationSkeleton>
                            <NotificationSkeleton
                                variant="rect"
                                width="840px"
                                height="260px"
                            ></NotificationSkeleton>
                        </div>
                    ) : (
                        <Grid container>
                            <Grid item xs={12} className="date-render">
                                <small>
                                    {notifications.data.publishFormat}
                                </small>
                            </Grid>
                            <Grid item xs={12} className="title-render">
                                <b>{notifications.data.title}</b>
                            </Grid>
                            <BodyRenderModal
                                item
                                xs={12}
                                className="body-render"
                            >
                                <RenderDescription
                                    renderComp={notifications.data.content}
                                ></RenderDescription>
                            </BodyRenderModal>
                        </Grid>
                    )}
                    <div></div>
                </NotificationModal>
                <Modal
                    open={showImportantNotification && isNotification}
                    themeColor={this?.state?.themeColor}
                    search={this?.state?.searchResults}
                    closeByClick={() =>
                        this.setState({ isNotification: false })
                    }
                />

                <div></div>
                <PlatformRulesModal
                    getPlatformRules={this.getTermsAndRegulation}
                />
            </>
        )
    }
}

Home.propTypes = {
    classes: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    const { users, authentication, sweetAlert, loader, generalRules } = state
    const { user } = authentication
    return {
        user,
        users,
        alert: sweetAlert.alert,
        loading: loader.loading,

        regulationData: state.regulation.regulationData,
        regulationDataFailed: state.regulation.regulationDataFailed,

        platformRulesData: state.platformRules.platformRulesData,
        platformRulesDataFailed: state.platformRules.platformRulesDataFailed,

        userData: users.userData ?? JSON.parse(localStorage.getItem('user')),
        generalRulesData: generalRules.generalRulesData,
        userDataFail: users.userDataFail,
        generalRulesdataFailed: generalRules.generalRulesdataFailed,

        consumerRegulationPost: state.users.consumerRegulationPost,
    }
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            PostConsumerRegulation,
            GetPlatformRulesData,
            GetRegulationData,
            GetCurrentConsumer,
            GetData,
            ShowLoader,
            HideLoader,
        },
        dispatch,
    )

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(appStyle),
    withStyles(checkStyle),
    withStyles(extendedFormsStyle),
    withTranslation(),
)(Home)
