import React from 'react'
import {
    GridContainer,
    GridItem,
    LabelText,
    MuiTypography,
} from '../../../../components/storybook'
import {
    TransferOriginAPI,
    formatAccountNumber,
    transferOriginAPIPoints,
    borderPoints,
    PixLabel,
    PixFormatValue,
} from '../../functions'
import {
    Card,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@mui/material'
import { Controller } from 'react-hook-form'

export function CardBankAccount({
    GetThemeColorFromStorage,
    transferInfo,
    control,
    selectedAccount,
    handleRadioChange,
    isSmallScreen,
}) {
    return (
        <GridContainer spacing={4} mt={1} ml={0} pl={0} pr={0}>
            <GridItem
                xs={12}
                sm={12}
                md={12}
                lg={12}
                mt={3}
                sx={{
                    paddingLeft: '0px !important',
                }}
            >
                <MuiTypography color="#333333" fontSize="14px" fontWeight={600}>
                    Selecione a conta de destino da transferência
                </MuiTypography>
            </GridItem>
            {transferInfo?.accounts?.length > 0 &&
                transferInfo?.accounts.map((item, index) => (
                    <GridItem
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        pl={0}
                        ml={0}
                        sx={{
                            paddingLeft: '0px !important',
                            paddingRight:
                                (index === 1 && '0px !important') ||
                                (isSmallScreen && '0px !important'),
                        }}
                    >
                        <Card
                            key={index}
                            sx={{
                                border:
                                    (index === 0 &&
                                        selectedAccount ===
                                            borderPoints(item, index)) ||
                                    (selectedAccount &&
                                        selectedAccount ===
                                            TransferOriginAPI(index))
                                        ? `2px solid ${
                                              GetThemeColorFromStorage()
                                                  ?.navigation?.buttons
                                                  ?.backgroundColor
                                          }`
                                        : '1px solid #C4C4C4',
                                borderRadius: '10px',
                                padding: '15px',
                                minHeight: '188px',
                                '& .MuiPaper-elevation1': {
                                    boxShadow: 'none',
                                },
                                boxShadow: 'none',
                                // opacity:
                                //     item?.transferType?.key === '2' && userPix
                                //         ? 0.5
                                //         : 1,
                                // pointerEvents:
                                //     item?.transferType?.key === '2' && userPix
                                //         ? 'none'
                                //         : 'auto',
                            }}
                        >
                            <GridContainer spacing={2}>
                                <GridItem xs={12} sx={12} md={12} lg={12}>
                                    <Controller
                                        name="accountBank"
                                        control={control}
                                        defaultValue={
                                            index === 0 &&
                                            item?.transferType?.key === '1'
                                                ? transferOriginAPIPoints(index)
                                                : TransferOriginAPI(index)
                                        }
                                        render={({ ...field }) => (
                                            <FormControl>
                                                <RadioGroup
                                                    {...field}
                                                    value={selectedAccount}
                                                    onChange={handleRadioChange}
                                                >
                                                    <FormControlLabel
                                                        value={
                                                            item?.transferType
                                                                ?.key
                                                        }
                                                        control={
                                                            <Radio
                                                                sx={{
                                                                    color: GetThemeColorFromStorage()
                                                                        ?.navigation
                                                                        ?.buttons
                                                                        ?.backgroundColor,
                                                                    fontSize:
                                                                        '25px',
                                                                    '&.Mui-checked':
                                                                        {
                                                                            color: GetThemeColorFromStorage()
                                                                                ?.navigation
                                                                                ?.buttons
                                                                                ?.backgroundColor,
                                                                        },
                                                                    '& .MuiSvgIcon-root':
                                                                        {
                                                                            fontSize: 25,
                                                                        },
                                                                }}
                                                            />
                                                        }
                                                        label={
                                                            <span
                                                                style={{
                                                                    color: '#333333',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {item
                                                                    ?.transferType
                                                                    ?.key ===
                                                                '1'
                                                                    ? item
                                                                          ?.transferType
                                                                          ?.value
                                                                    : 'Conta bancária'}
                                                            </span>
                                                        }
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        )}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} lg={6} mb={1}>
                                    {item?.transferType?.key === '1' ? (
                                        <LabelText
                                            label={PixLabel(
                                                item?.info?.keyType?.value ||
                                                    '',
                                            )}
                                            value={
                                                <span
                                                    style={{
                                                        color: GetThemeColorFromStorage()
                                                            ?.navigation
                                                            ?.buttons
                                                            ?.backgroundColor,
                                                        fontWeight: 600,
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    {PixFormatValue(
                                                        item?.info?.keyType
                                                            ?.value,
                                                        item?.info?.key,
                                                    )}
                                                </span>
                                            }
                                        />
                                    ) : (
                                        <LabelText
                                            label="Conta bancária"
                                            value={
                                                <span
                                                    style={{
                                                        color: GetThemeColorFromStorage()
                                                            ?.navigation
                                                            ?.buttons
                                                            ?.backgroundColor,
                                                        fontWeight: 600,
                                                        fontSize: '14px',
                                                        maxWidth: '170px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow:
                                                            'ellipsis',
                                                    }}
                                                >
                                                    {item?.info?.bank?.value}
                                                </span>
                                            }
                                        />
                                    )}
                                </GridItem>
                                {item?.transferType?.key !== '1' && (
                                    <>
                                        <GridItem
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={6}
                                            mb={1}
                                            sx={{
                                                paddingLeft: isSmallScreen
                                                    ? 0
                                                    : '95px!important',
                                            }}
                                        >
                                            <LabelText
                                                label="Agência"
                                                value={
                                                    <span
                                                        style={{
                                                            color: GetThemeColorFromStorage()
                                                                ?.navigation
                                                                ?.buttons
                                                                ?.backgroundColor,
                                                            fontWeight: 600,
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        {item?.info?.branch}
                                                    </span>
                                                }
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6} lg={6}>
                                            <LabelText
                                                label="Conta"
                                                value={
                                                    <span
                                                        style={{
                                                            color: GetThemeColorFromStorage()
                                                                ?.navigation
                                                                ?.buttons
                                                                ?.backgroundColor,
                                                            fontWeight: 600,
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        {formatAccountNumber(
                                                            item?.info?.number,
                                                        )}
                                                    </span>
                                                }
                                            />
                                        </GridItem>
                                        <GridItem
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={6}
                                            sx={{
                                                paddingLeft: isSmallScreen
                                                    ? 0
                                                    : '95px!important',
                                            }}
                                        >
                                            <LabelText
                                                label="Dígito"
                                                value={
                                                    <span
                                                        style={{
                                                            color: GetThemeColorFromStorage()
                                                                ?.navigation
                                                                ?.buttons
                                                                ?.backgroundColor,
                                                            fontWeight: 600,
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        {
                                                            item?.info
                                                                ?.numberDigit
                                                        }
                                                    </span>
                                                }
                                            />
                                        </GridItem>
                                    </>
                                )}
                            </GridContainer>
                        </Card>
                    </GridItem>
                ))}
        </GridContainer>
    )
}
