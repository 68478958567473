import { formatBRCurrency } from '../../../components/storybook'
import { GetThemeColorFromStorage } from '../../../redux/api/themeColor'

export function validateMaskTotal(data) {
    const pointsMask = data?.simpleRules?.programCurrencySymbol

    if (data?.typeOfBenefit?.value === 'Cashback') {
        return formatBRCurrency(data?.sellQuantity?.totalAllowed * data?.value)
    }
    if (data?.typeOfBenefit?.value === 'Points') {
        return `${pointsMask} ${data?.sellQuantity?.totalAllowed * data?.value}`
    }
}
export function validateMaskBalance(data) {
    const pointsMask = data?.simpleRules?.programCurrencySymbol

    if (data?.typeOfBenefit?.value === 'Cashback') {
        return formatBRCurrency(data?.sellQuantity?.remaining * data?.value)
    }
    if (data?.typeOfBenefit?.value === 'Points') {
        return `${pointsMask} ${data?.sellQuantity?.remaining * data?.value}`
    }
}

export function handleBackground(item) {
    if (item && item?.backgroundColor !== 'string') {
        return item?.backgroundColor
    } else {
        return GetThemeColorFromStorage()?.navigation?.buttons?.backgroundColor
    }
}

export function handleDescriptionSuccess(item) {
    const pointsMask = localStorage?.programCurrencySymbol
    const pointsName = localStorage?.programCurrencyName

    if (item && item?.paidSurvey?.key === '1' && item?.value) {
        const amount = item?.value || 0
        const formattedAmount = formatBRCurrency(amount)
        const currencyLabel = amount === 1 ? 'real' : 'reais'

        return `PARABÉNS, ${formattedAmount} ${currencyLabel} foram adicionados na sua pontuação`
    }

    if (item && item?.paidSurvey?.key === '2' && item?.value) {
        return `PARABÉNS ${pointsMask} ${item?.value} ${pointsName} foram adicionados na sua pontuação`
    }
}
