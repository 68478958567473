/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react'
import { NegotiationService } from '../Services'
import { useTranslation } from 'react-i18next'
import { GetThemeColorFromStorage } from '../../../redux/api/themeColor'

export default function useNegotiation() {
    const { t } = useTranslation()
    const [theme, setTheme] = useState(GetThemeColorFromStorage())

    const negotiationService = new NegotiationService()
    const [isLoadingData, setIsLoadingData] = useState(false)

    const [negotiationData, setNegotiationData] = useState([])
    const [negotiationModalOpen, setNegotiationModalOpen] = useState(false)
    const [cancelNegotiationModalOpen, setCancelNegotiationModalOpen] =
        useState(false)
    const [isFilterData, setIsFilterData] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [cancelItem, setCancelItem] = useState(null)
    const [tablePagination, setTablePagination] = useState({
        page: 1,
        pageSize: 5,
        totalPages: 1,
    })
    const [paginationCashback, setPaginationCashback] = useState({
        page: 1,
        pageSize: 5,
        totalPages: 1,
    })
    const [notificationMessage, setNotificationMessage] = useState({
        open: false,
        message: 'E-mail enviado com sucesso!',
        type: 'success',
    })

    function changeNotificationMessage(key, value) {
        setNotificationMessage((prev) => {
            return {
                ...prev,
                [key]: value,
            }
        })
    }

    function convertOptions(data) {
        let result = []

        data.forEach((item) => {
            result.push({
                key: item.key,
                value: item.value,
                label: t(`NEGOTIATION.${item.value}`),
            })
        })

        return result
    }

    const getNegotiationData = useCallback(async (type = 'points') => {
        setIsLoadingData(true)

        const response = await negotiationService.getNegotiations(type)
        if (response) {
            setNegotiationData(response?.results)
            setTablePagination({
                page: response?.currentPage,
                pageSize: response?.pageSize,
                totalPages: response?.pageCount,
            })
            setPaginationCashback({
                page: response?.currentPage,
                pageSize: response?.pageSize,
                totalPages: response?.pageCount,
            })
        }
        setIsLoadingData(false)
    }, [])

    const getNegotiationFilter = useCallback(async (id) => {
        setIsLoadingData(true)
        const response = await negotiationService.getFilteredNegotiations(id)

        setNegotiationData(response?.results)
        if (response?.results?.length < 1) {
            setIsFilterData(true)
        }

        setIsLoadingData(false)
    }, [])

    const getPaginatedNegotiations = useCallback(
        async (key, value, tablePagination) => {
            setIsLoadingData(true)

            let filter = {
                ...tablePagination,
            }
            if (key === 'page') {
                filter.page = value
            } else {
                filter.pageSize = value
                filter.page = 1
            }

            const response = await negotiationService.getPaginationNegotiations(
                {
                    page: filter.page,
                    pageSize: filter.pageSize,
                },
            )
            if (response) {
                setNegotiationData(response?.results)
                setTablePagination({
                    page: response?.currentPage,
                    pageSize: response?.pageSize,
                    totalPages: response?.pageCount,
                })
                setPaginationCashback({
                    page: response?.currentPage,
                    pageSize: response?.pageSize,
                    totalPages: response?.pageCount,
                })
            }
            setIsLoadingData(false)
        },
        [],
    )
    const getNegotiationNotification = useCallback(async (id) => {
        const response = await negotiationService.getNegotiationNotification(id)
        if (response?.errors.length > 0) {
            changeNotificationMessage(
                'message',
                'Ocorreu um erro ao enviar o E-mail!',
            )
            changeNotificationMessage('type', 'error')
            changeNotificationMessage('open', true)
            setNegotiationModalOpen(false)
        } else {
            setNegotiationModalOpen(false)
            changeNotificationMessage('open', true)
            await getNegotiationData()
        }
    }, [])
    const cancelNegotiation = useCallback(async (id) => {
        await negotiationService.CancelNegotiation(id)
        setCancelNegotiationModalOpen(false)
        await getNegotiationData()
    }, [])

    function handleCloseStack() {
        changeNotificationMessage('open', false)
    }

    useEffect(() => {
        setTheme(GetThemeColorFromStorage())
    }, [])

    return {
        getNegotiationData,
        getNegotiationFilter,
        isLoadingData,
        setIsLoadingData,
        negotiationData,
        setNegotiationData,
        tablePagination,
        paginationCashback,
        getPaginatedNegotiations,
        negotiationModalOpen,
        setNegotiationModalOpen,
        cancelNegotiationModalOpen,
        setCancelNegotiationModalOpen,
        isFilterData,
        convertOptions,
        getNegotiationNotification,
        selectedItem,
        setSelectedItem,
        notificationMessage,
        changeNotificationMessage,
        handleCloseStack,
        cancelNegotiation,
        cancelItem,
        setCancelItem,
        setTablePagination,
        setPaginationCashback,
        t,
        theme,
        setTheme,
    }
}
