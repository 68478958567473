import React, { memo, useState } from 'react'
import { Grid, IconButton, InputAdornment } from '@material-ui/core'
import { Controller } from 'react-hook-form'
import PasswordStrengthBars from 'react-password-strength-bar'

import * as Style from './styles'
import RegisterInput from 'components/RegisterInput'
import { Visibility, VisibilityOff } from '@material-ui/icons'

import useWindowSize from '../../../../utils/useWindowSize'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/material'
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'

const policyArray = [
    'Deve ter no mínimo 8 caracteres;',
    'Deve ter no máximo 20 caracteres;',
    'Deve conter letras, números e caracteres especiais',
    'Não deve conter mais de 3 números sequenciais;',
    'Não deve conter números repetidos em ordem;',
    'Não pode ser igual ao CPF, CNPJ, Data de Nascimento, Telefone ou celular.',
]

function AccessData({
    errors,
    control,
    watch,
    generalRules,
    userCpf,
    ...form
}) {
    const [themeColor, setThemeColor] = useState(GetThemeColorFromStorage())
    const { t } = useTranslation()
    const values = watch('password')
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [showTooltip, setShowTooltip] = useState(false)

    const windowSize = useWindowSize()

    return (
        <>
            <Style.CustomTitle>
                <b>{t('FIELD_REGISTER_ACCESS')}</b>
            </Style.CustomTitle>
            <Style.Container container md={6} xs={12}>
                <Grid item xs={12} md={6} style={{ padding: '0px 8px 0px' }}>
                    <Controller
                        as={
                            <RegisterInput
                                label={t('LOGIN_PASSWORD')}
                                error={!!errors.password}
                                helperText={t(errors?.password?.message)}
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                disableRipple
                                                aria-label="toggle password visibility"
                                                onClick={() =>
                                                    setShowPassword(
                                                        !showPassword,
                                                    )
                                                }
                                            >
                                                {showPassword ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        }
                        name="password"
                        control={control}
                    />
                    <PasswordStrengthBars
                        style={{
                            width: '100%',
                        }}
                        password={values}
                        minLength={2}
                        shortScoreWord={'muito curto'}
                        // onChangeScore={handleChangeScore}
                        barColors={[
                            '#dddddd',
                            '#ef4836',
                            '#f6b44d',
                            '#2b90ef',
                            '#25c281',
                        ]}
                        scoreWords={[
                            'muito fraca',
                            'fraca',
                            'regular',
                            'boa',
                            'forte',
                        ]}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    justify="flex-end"
                    style={{ padding: '0px 8px 0px' }}
                >
                    <Controller
                        as={
                            <RegisterInput
                                label={t('CONFIRM_THE_PASSWORD')}
                                error={!!errors.confirmPassword}
                                helperText={t(errors?.confirmPassword?.message)}
                                type={showConfirmPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                disableRipple
                                                aria-label="toggle password visibility"
                                                onClick={() =>
                                                    setShowConfirmPassword(
                                                        !showConfirmPassword,
                                                    )
                                                }
                                            >
                                                {showConfirmPassword ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        }
                        name="confirmPassword"
                        control={control}
                    />
                    <Stack alignItems="end">
                        <Style.PolicyTooltip
                            open={showTooltip}
                            leaveTouchDelay={3000}
                            enterTouchDelay={50}
                            onMouseEnter={() =>
                                setShowTooltip(
                                    windowSize.width > 400 ? true : false,
                                )
                            }
                            onClose={() => setShowTooltip(false)}
                            // onClick={() => setShowTooltip(!showTooltip)}
                            arrow
                            title={
                                <Style.BoxPolicy>
                                    <b>Regras:</b>
                                    <ul
                                        style={{
                                            marginLeft: '-18px',
                                        }}
                                    >
                                        {policyArray.map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ul>
                                </Style.BoxPolicy>
                            }
                            placement="bottom-end"
                        >
                            <Style.PoliticyText
                                themeColor={themeColor}
                                // onClick={() => setShowTooltip(!showTooltip)}
                            >
                                {t('PASSWORD_POLICY')}
                            </Style.PoliticyText>
                        </Style.PolicyTooltip>
                    </Stack>
                </Grid>

                <Style.ArrowPolicyTooltipResponsive />
                <Style.PolicyTooltipResponsive>
                    <p style={{ fontWeight: 'bold', paddingLeft: '24px' }}>
                        Regras:
                    </p>
                    <ul>
                        <li>Deve ter no mínimo 8 e máximo de 20 caracteres;</li>
                        <li>
                            Deve conter letras, números e caracteres especiais;
                        </li>
                        <li>Não deve conter mais de 3 números sequenciais;</li>
                        <li>Não deve conter números repetidos em ordem;</li>
                        <li>
                            Não ser igual ao CPF, CNPJ, data de nascimento,
                            telefone ou celular;
                        </li>
                    </ul>
                </Style.PolicyTooltipResponsive>
            </Style.Container>
        </>
    )
}

export default memo(AccessData)
