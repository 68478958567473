/* eslint-disable no-undef */
import { makeStyles } from '@material-ui/core'

export const removeRoutes = (obj, dashRoutes) => {
    if (!dashRoutes) return

    for (let i = 0; i < dashRoutes.length; i++) {
        if (dashRoutes[i]?.id === 'tiers' && !obj?.allowProgramLevels) {
            dashRoutes[i].sidebar = false
        }
        if (dashRoutes[i]?.id === 'transferRequest' && !obj?.allowTransfer) {
            dashRoutes[i].sidebar = false
        }
        if (dashRoutes[i]?.id === 'giftVouchers' && !obj?.allowGiftCard) {
            dashRoutes[i].sidebar = false
        }

        if (dashRoutes[i]?.id === 'payrollDebit' && !obj?.allowPayrollDebit) {
            dashRoutes[i].sidebar = false
        }
    }

    return dashRoutes
}

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}))
