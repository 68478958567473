/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Autocomplete, CircularProgress, TextField, } from "@mui/material";
import React from "react";
import { MuiTypography } from "../Typography";
import { AlertModalIcon } from "../Icons";
import { MuiStack } from "../Stack";
import { MuiBox } from "../Box";
import { InputStack } from "../Stack/InputStack";
export function MuiAutocomplete(_a) {
    var _b = _a.options, options = _b === void 0 ? [] : _b, defaultColor = _a.defaultColor, required = _a.required, standard = _a.standard, label = _a.label, width = _a.width, backgroundColor = _a.backgroundColor, value = _a.value, disabled = _a.disabled, textLabel = _a.textLabel, labelColor = _a.labelColor, showLabel = _a.showLabel, errorText = _a.errorText, showError = _a.showError, InputProps = _a.InputProps, onChange = _a.onChange, defaultValue = _a.defaultValue, placeholder = _a.placeholder, onInput = _a.onInput, noOptionsText = _a.noOptionsText, field = _a.field, helperText = _a.helperText, error = _a.error, sx = _a.sx, loading = _a.loading, props = __rest(_a, ["options", "defaultColor", "required", "standard", "label", "width", "backgroundColor", "value", "disabled", "textLabel", "labelColor", "showLabel", "errorText", "showError", "InputProps", "onChange", "defaultValue", "placeholder", "onInput", "noOptionsText", "field", "helperText", "error", "sx", "loading"]);
    var color = defaultColor !== null && defaultColor !== void 0 ? defaultColor : "#F58B3C";
    var hasValue = Boolean(value);
    var hasLabel = Boolean(showLabel && textLabel && hasValue);
    var handleChange = function (event, newValue) {
        if (onChange) {
            onChange(event, newValue);
        }
    };
    return (_jsxs(InputStack, { children: [_jsx(MuiBox, __assign({ className: "label-container ".concat(hasLabel ? "has-label" : "") }, { children: textLabel && (_jsx(MuiTypography, __assign({ variant: "body2", color: labelColor !== null && labelColor !== void 0 ? labelColor : "#989898", style: {
                        fontSize: "13px",
                        lineHeight: "20px",
                    } }, { children: textLabel }))) })), _jsx(Autocomplete, __assign({}, props, { disablePortal: true, "data-testid": standard ? "mui-input-standard" : "mui-input", options: options, value: value, onChange: handleChange, disabled: disabled, defaultValue: defaultValue, onInput: onInput, noOptionsText: noOptionsText !== null && noOptionsText !== void 0 ? noOptionsText : "Nenhuma opção", getOptionLabel: function (option) { return (option === null || option === void 0 ? void 0 : option.label) || ""; }, sx: __assign(__assign({}, sx), { width: width !== null && width !== void 0 ? width : "100%", height: "85px", ".MuiAutocomplete-endAdornment": {
                        display: showError && "none",
                    } }), renderInput: function (params) {
                    var _a;
                    return (_jsxs(MuiStack, __assign({ direction: "row", alignItems: "center" }, { children: [_jsx(TextField, __assign({}, params, { "data-testid": standard ? "mui-input-standard" : "mui-input", size: "small", variant: standard ? "standard" : "outlined", disabled: disabled, placeholder: placeholder, error: error, helperText: helperText !== null && helperText !== void 0 ? helperText : "", value: value, InputLabelProps: __assign({ shrink: true }, InputProps), InputProps: __assign(__assign({}, params.InputProps), { endAdornment: (_jsxs(React.Fragment, { children: [loading ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }), sx: __assign(__assign({}, sx), { backgroundColor: (_a = ((disabled && "#F2F2F2") || backgroundColor)) !== null && _a !== void 0 ? _a : "#FFFFFF", "& .MuiOutlinedInput-root": {
                                        height: "40px",
                                        "& input": {
                                            padding: "8px 15px",
                                            height: "24px",
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: color,
                                        },
                                    }, "& label.Mui-focused": {
                                        color: color,
                                    }, "& .MuiInputBase-root.Mui-focused, & .MuiOutlinedInput-root.Mui-focused .MuiInputAdornment-root": {
                                        borderColor: color,
                                    }, "& .MuiInput-underline.Mui-focused:after": {
                                        borderBottomColor: color,
                                    } }), fullWidth: true })), showError && (_jsx(MuiBox, __assign({ "data-testid": "error-icon", sx: {
                                    position: "relative",
                                    left: "-35px",
                                    top: "4px",
                                    width: "1px",
                                } }, { children: _jsx(AlertModalIcon, { size: 22, color: "#BE2C2C" }) })))] })));
                } })), showError && (_jsx(MuiBox, __assign({ height: "25px" }, { children: _jsx(MuiTypography, __assign({ variant: "body2", pb: 0.6, color: "#BE2C2C" }, { children: errorText !== null && errorText !== void 0 ? errorText : " " })) })))] }));
}
